import React from "react"

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 429.67 218.65"
    className="logo"
    preserveAspectRatio="true"
  >
    <title>logo</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_6" data-name="Layer 6">
        <g>
          <g>
            <path d="M101.6,72.06A66.63,66.63,0,0,0,0,128.7V197a21.66,21.66,0,1,0,43.31,0V128.7a23.32,23.32,0,0,1,46.64,0V197a21.66,21.66,0,1,0,43.31,0V128.7A66.5,66.5,0,0,0,101.6,72.06Z" />
            <path d="M270.37,62.07a21.65,21.65,0,0,0-21.65,21.65V152a23.32,23.32,0,0,1-46.64,0V83.72a21.66,21.66,0,0,0-43.31,0V152A66.63,66.63,0,0,0,292,152V83.72A21.65,21.65,0,0,0,270.37,62.07Z" />
            <path d="M429.61,83.14a21.6,21.6,0,0,0-43.19,0h-.06V197a21.66,21.66,0,1,0,43.31,0V83.14Z" />
            <path d="M360.79,83.14a21.6,21.6,0,0,0-43.19,0h-.06V197a21.66,21.66,0,1,0,43.31,0V83.14Z" />
          </g>
          <circle cx="339.19" cy="21.65" r="21.65" />
        </g>
      </g>
    </g>
  </svg>
)

export default Logo
